import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  aspect-ratio: 1.2;

  > img {
    z-index: 3;
    position: absolute;
    transition: all 0.8s ease;
  }

  > img:nth-child(1) {
    top: 10%;
    left: 0;
    width: 30%;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(-100%, 0, 0);
          `}
  }

  > img:nth-child(2) {
    top: -5%;
    right: 0;
    width: 20%;
    transition-delay: 0.2s;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(100%, 0, 0);
          `}
  }

  ${({ theme }) => theme.media.m} {
    aspect-ratio: 1.3;
  }

  ${({ theme }) => theme.media.l} {
    aspect-ratio: 1.5;

    > img:nth-child(1),
    > img:nth-child(2) {
      width: auto;
    }
  }
`

export const Cards = styled.div`
  > img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.8s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, -20%, 0);
          `}
  }

  > img:nth-child(1) {
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    transition-delay: 0.2s;
  }

  > img:nth-child(2) {
    z-index: 1;
    top: 10%;
    left: 38%;
    width: 65%;
    transition-delay: 0.4s;
  }

  > img:nth-child(3) {
    z-index: 2;
    top: 19%;
    left: 46%;
    width: 43%;
    transition-delay: 0.6s;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0) rotate(-62deg);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, -20%, 0) rotate(-27deg);
          `}
  }
`
