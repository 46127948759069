import styled from 'styled-components'

export const Wrapper = styled.section``

export const Content = styled.article`
  line-height: 1.66;

  > div {
    transition: opacity 0.8s 0.5s ease;
    opacity: ${({ active }) => (active ? '1' : '0')};

    &:nth-child(2) {
      transition-delay: 0.8s;
    }
  }

  p {
    margin: 20px auto;
    max-width: 600px;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66;
    color: black;
  }

  ${({ theme }) => theme.media.l} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 0;

    > div:nth-child(1) {
      order: 2;
    }
  }
`
