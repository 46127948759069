import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: -1;
  position: relative;
  margin: -20% 0 0;
  width: 100%;
  aspect-ratio: 1.59;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.8s 0.8s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, -20%, 0);
          `}
  }
`
