import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Wrapper } from './styles.js'

import BgImg1 from '@images/works/oracle-cards/final.png'

const SectionFinal = () => {
  const [line1InView, setLine1InView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setLine1InView(true)}
      threshold={0.25}
    >
      <Wrapper active={line1InView}>
        <img src={BgImg1} />
      </Wrapper>
    </InView>
  )
}

export default SectionFinal
