import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 30px;
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;

  > a > span {
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-align: center;

    > span > span {
      position: relative;
      display: inline-block;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: transform 0.8s 0s ease;
    }

    span + span {
      padding-left: 5px;
    }

    > span:last-child {
      color: ${({ theme }) => theme.colors.purple};
    }

    > span:nth-child(2) > span {
      transition-delay: 0.2s;
    }

    > span:nth-child(3) > span {
      transition-delay: 0.4s;
    }
  }

  ${({ theme }) => theme.media.m} {
    padding: 90px 30px;
    font-size: 30px;
  }

  ${({ theme }) => theme.media.l} {
    padding: 100px 30px;
    font-size: 32px;
  }
`
