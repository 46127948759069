import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import Hero from '@layout/worksBlocks/Oracle/Hero'

import Credits from '@blocks/Credits'
import Next from '@blocks/Next'
import Spacer from '@blocks/Spacer'
import Section1 from '@layout/worksBlocks/Oracle/Section1'
import Section2 from '@layout/worksBlocks/Oracle/Section2'
import Section3 from '@layout/worksBlocks/Oracle/Section3'
import Section4 from '@layout/worksBlocks/Oracle/Section4'
import Section5 from '@layout/worksBlocks/Oracle/Section5'
import SectionFinal from '@layout/worksBlocks/Oracle/SectionFinal'
import Cards from '@layout/worksBlocks/Oracle/Cards'

const ProjectOracle = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsProjectOracle
  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  return (
    <Main
      seo={pageData.seoMetaTags}
      enterColor={pageData.color.hex}
      transitionStatus={transitionStatus}
    >
      <Hero
        active={startPage && transitionStatus === 'entered'}
        path="oracle"
      />
      <Credits data={pageData.credits[0]} />
      <Spacer size="m" />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Spacer size="m" />
      <Cards />
      <SectionFinal />
      <Next data={pageData.nextProject} />
    </Main>
  )
}

export const query = graphql`
  query ProjectOracleQuery {
    datoCmsProjectOracle {
      slug
      name
      seoMetaTags {
        tags
      }
      color {
        hex
      }
      credits {
        id
        header
        description
        credits
      }
      nextProject {
        ... on DatoCmsProject {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectSpotify {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectFroodie {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectJayne {
          id
          name
          slug
          color {
            hex
          }
        }
      }
    }
  }
`

export default ProjectOracle
