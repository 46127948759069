import React from 'react'
import PropTypes from 'prop-types'
import { Parallax, Background } from 'react-parallax'
import { Wrapper, Image, Video, VideoCurtain, Phones } from './styles.js'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import HeroPhoto from '@images/works/oracle-cards/hero-bg.png'
import HeroPhone1 from '@images/works/oracle-cards/hero-card1.png'
import HeroPhone2 from '@images/works/oracle-cards/hero-card2.png'
import HeroPhone3 from '@images/works/oracle-cards/hero-card3.png'
import HeroPhone4 from '@images/works/oracle-cards/hero-card4.png'

const Hero = ({ active, path }) => {
  return (
    <Wrapper active={active}>
      <Video active={true} path={path}>
        <div>
          <Parallax strength={300}>
            <Background className="custom-bg">
              <Image src={HeroPhoto} active={active} />
            </Background>
          </Parallax>
        </div>
        <Phones active={active}>
          <img src={HeroPhone1} />
          <img src={HeroPhone2} />
          <img src={HeroPhone3} />
          <img src={HeroPhone4} />
        </Phones>
        <VideoCurtain active={active}>
          <div></div>
          <div></div>
          <div></div>
        </VideoCurtain>
      </Video>
      <HorWrap>
        <PurpleHeader
          active={active}
          delay={3}
          color="black"
          text={['Oracle', 'Cards']}
          breakText
        />
      </HorWrap>
    </Wrapper>
  )
}

Hero.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Hero.defaultProps = {
  active: false,
  children: null,
}

export default Hero
