import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  font-size: 20px;
  ${({ showSize }) => showSize && 'background: #f1f1f1;'}
  ${({ size }) => {
    switch (size) {
      case 'xs':
        return css`
          height: 20px;
        `
      case 's':
        return css`
          height: 40px;
        `
      case 'm':
        return css`
          height: 80px;
        `
      case 'l':
        return css`
          height: 120px;
        `
      case 'xl':
        return css`
          height: 160px;
        `
      case 'xxl':
        return css`
          height: 220px;
        `
      default:
        return 'height: 4px;'
    }
  }}
`
