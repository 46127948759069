import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const Spacer = ({ size, showSize }) => {
  return showSize ? (
    <Wrapper size={size} showSize={true}>
      {size}
    </Wrapper>
  ) : (
    <Wrapper size={size} />
  )
}

Spacer.propTypes = {
  size: PropTypes.oneOf(['xs, s, m, l, xl, xxl']),
  showSize: PropTypes.bool,
}

Spacer.defaultProps = {
  size: 'xs',
  showSize: false,
}

export default Spacer
