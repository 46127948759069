import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import { InView } from 'react-intersection-observer'

import { Wrapper } from './styles.js'

const Next = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <TransitionLink
          to={`/work/${data.slug}`}
          title={data.name}
          exit={{
            length: 1.2,
            trigger: () => {
              localStorage.setItem('color', data.color.hex)
            },
          }}
          entry={{
            delay: 1.2,
          }}
        >
          <span>
            <span>
              <span>Next</span>
            </span>
            <span>
              <span>project</span>
            </span>
            <span>
              <span>{data.name}</span>
            </span>
          </span>
        </TransitionLink>
      </Wrapper>
    </InView>
  )
}

Next.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Next.defaultProps = {
  data: null,
}

export default Next
