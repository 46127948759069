import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
  padding: 0 10px;

  ${({ theme }) => theme.media.m} {
    padding: 0 20px;
  }

  ${({ theme }) => theme.media.l} {
    padding: 0 30px;
  }
`

export const CardsLine = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  margin: 0 0 5px;
  width: 100%;

  img {
    width: 100%;
    transition: all 0.6s 0.5s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}

    &:nth-child(2) {
      transition-delay: 0.7s;
    }

    &:nth-child(3) {
      transition: opacity 0.6s 0.9s ease, transform 0.9s 1.9s ease;
      ${({ active, last }) =>
        last && active
          ? css`
              transform-origin: bottom left;
              transform: rotate(15deg);
            `
          : css`
              transform-origin: bottom left;
              transform: rotate(0deg);
            `}
    }

    &:nth-child(4) {
      display: block;
      transition-delay: 1.1s;
    }

    &:nth-child(5) {
      transition-delay: 1.3s;
    }
  }

  ${({ theme }) => theme.media.m} {
    gap: 7px;
    margin: 0 0 7px;
  }

  ${({ theme }) => theme.media.l} {
    gap: 10px;
    margin: 0 0 10px;
  }
`
