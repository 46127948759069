import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1;
  position: relative;
  width: 100%;
  aspect-ratio: 1.3;

  > img {
    z-index: 4;
    position: absolute;
    transition: all 0.8s ease;
  }

  > img:nth-child(1) {
    top: 0;
    left: -15%;
    width: 30%;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(-100%, 0, 0);
          `}
  }

  > img:nth-child(2) {
    z-index: 0;
    top: -25%;
    right: 0;
    width: 42%;
    transition-delay: 1.2s;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(100%, 0, 0);
          `}
  }

  > img:nth-child(3) {
    bottom: 20%;
    right: 0;
    width: 15%;
    transition-delay: 1.4s;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(100%, 0, 0);
          `}
  }
`

export const Cards = styled.div`
  > img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.8s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20%, 0);
          `}
  }

  > img:nth-child(1) {
    z-index: 1;
    top: 15%;
    left: -3%;
    width: 55%;
    transition-delay: 0.2s;
  }

  > img:nth-child(2) {
    z-index: 1;
    top: 20%;
    left: 42%;
    width: 31%;
    transition-delay: 0.4s;
  }

  > img:nth-child(3) {
    z-index: 2;
    top: 35%;
    left: 55%;
    width: 38%;
    transition-delay: 0.6s;
  }

  > img:nth-child(4) {
    z-index: 1;
    top: 75%;
    left: auto;
    right: 0%;
    width: 44%;
    transition-delay: 0.8s;
  }
`
