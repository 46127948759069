import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Wrapper, CardsLine } from './styles.js'

import CardImg1 from '@images/works/oracle-cards/cards-list/card1.png'
import CardImg2 from '@images/works/oracle-cards/cards-list/card2.png'
import CardImg3 from '@images/works/oracle-cards/cards-list/card3.png'
import CardImg4 from '@images/works/oracle-cards/cards-list/card4.png'
import CardImg5 from '@images/works/oracle-cards/cards-list/card5.png'
import CardImg6 from '@images/works/oracle-cards/cards-list/card6.png'
import CardImg7 from '@images/works/oracle-cards/cards-list/card7.png'
import CardImg8 from '@images/works/oracle-cards/cards-list/card8.png'
import CardImg9 from '@images/works/oracle-cards/cards-list/card9.png'
import CardImg10 from '@images/works/oracle-cards/cards-list/card10.png'
import CardImg11 from '@images/works/oracle-cards/cards-list/card11.png'
import CardImg12 from '@images/works/oracle-cards/cards-list/card12.png'
import CardImg13 from '@images/works/oracle-cards/cards-list/card13.png'
import CardImg14 from '@images/works/oracle-cards/cards-list/card14.png'
import CardImg15 from '@images/works/oracle-cards/cards-list/card15.png'
import CardImg16 from '@images/works/oracle-cards/cards-list/card16.png'
import CardImg17 from '@images/works/oracle-cards/cards-list/card17.png'
import CardImg18 from '@images/works/oracle-cards/cards-list/card18.png'
import CardImg19 from '@images/works/oracle-cards/cards-list/card19.png'
import CardImg20 from '@images/works/oracle-cards/cards-list/card20.png'
import CardImg21 from '@images/works/oracle-cards/cards-list/card21.png'
import CardImg22 from '@images/works/oracle-cards/cards-list/card22.png'
import CardImg23 from '@images/works/oracle-cards/cards-list/card23.png'
import CardImg24 from '@images/works/oracle-cards/cards-list/card24.png'
import CardImg25 from '@images/works/oracle-cards/cards-list/card25.png'
import CardImg26 from '@images/works/oracle-cards/cards-list/card26.png'
import CardImg27 from '@images/works/oracle-cards/cards-list/card27.png'
import CardImg28 from '@images/works/oracle-cards/cards-list/card28.png'
import CardImg29 from '@images/works/oracle-cards/cards-list/card29.png'
import CardImg30 from '@images/works/oracle-cards/cards-list/card30.png'
import CardImg31 from '@images/works/oracle-cards/cards-list/card31.png'
import CardImg32 from '@images/works/oracle-cards/cards-list/card32.png'
import CardImg33 from '@images/works/oracle-cards/cards-list/card33.png'
import CardImg34 from '@images/works/oracle-cards/cards-list/card34.png'
import CardImg35 from '@images/works/oracle-cards/cards-list/card35.png'
import CardImg36 from '@images/works/oracle-cards/cards-list/card36.png'
import CardImg37 from '@images/works/oracle-cards/cards-list/card37.png'
import CardImg38 from '@images/works/oracle-cards/cards-list/card38.png'
import CardImg39 from '@images/works/oracle-cards/cards-list/card39.png'
import CardImg40 from '@images/works/oracle-cards/cards-list/card40.png'
import CardImg41 from '@images/works/oracle-cards/cards-list/card41.png'
import CardImg42 from '@images/works/oracle-cards/cards-list/card42.png'
import CardImg43 from '@images/works/oracle-cards/cards-list/card43.png'

const Cards = () => {
  const [line1InView, setLine1InView] = useState(false)
  const [line2InView, setLine2InView] = useState(false)
  const [line3InView, setLine3InView] = useState(false)
  const [line4InView, setLine4InView] = useState(false)
  const [line5InView, setLine5InView] = useState(false)
  const [line6InView, setLine6InView] = useState(false)
  const [line7InView, setLine7InView] = useState(false)
  const [line8InView, setLine8InView] = useState(false)
  const [line9InView, setLine9InView] = useState(false)

  return (
    <Wrapper>
      <InView
        onChange={(inView, entry) => inView && setLine1InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line1InView}>
          <img src={CardImg1} />
          <img src={CardImg2} />
          <img src={CardImg3} />
          <img src={CardImg4} />
          <img src={CardImg5} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine2InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line2InView}>
          <img src={CardImg6} />
          <img src={CardImg7} />
          <img src={CardImg8} />
          <img src={CardImg9} />
          <img src={CardImg10} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine3InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line3InView}>
          <img src={CardImg11} />
          <img src={CardImg12} />
          <img src={CardImg13} />
          <img src={CardImg14} />
          <img src={CardImg15} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine4InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line4InView}>
          <img src={CardImg16} />
          <img src={CardImg17} />
          <img src={CardImg18} />
          <img src={CardImg19} />
          <img src={CardImg20} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine5InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line5InView}>
          <img src={CardImg21} />
          <img src={CardImg22} />
          <img src={CardImg23} />
          <img src={CardImg24} />
          <img src={CardImg25} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine6InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line6InView}>
          <img src={CardImg26} />
          <img src={CardImg27} />
          <img src={CardImg28} />
          <img src={CardImg29} />
          <img src={CardImg30} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine7InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line7InView}>
          <img src={CardImg31} />
          <img src={CardImg32} />
          <img src={CardImg33} />
          <img src={CardImg34} />
          <img src={CardImg35} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine8InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line8InView}>
          <img src={CardImg36} />
          <img src={CardImg37} />
          <img src={CardImg38} />
          <img src={CardImg39} />
          <img src={CardImg40} />
        </CardsLine>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setLine9InView(true)}
        threshold={0.25}
      >
        <CardsLine active={line9InView} last>
          <img src={CardImg41} />
          <img src={CardImg42} />
          <img src={CardImg43} />
        </CardsLine>
      </InView>
    </Wrapper>
  )
}

export default Cards
