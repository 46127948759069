import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: 0;
  position: relative;
  width: 100%;
  aspect-ratio: 1.3;

  > img {
    z-index: 4;
    position: absolute;
    transition: all 0.8s ease;
  }

  > img:nth-child(1) {
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(0, 20%, 0);
          `}
  }

  > img:nth-child(2) {
    bottom: 17%;
    left: 30%;
    width: 19%;
    transition-delay: 1.2s;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20%, 0);
          `}
  }

  > img:nth-child(3) {
    bottom: -20%;
    right: 0;
    width: 30%;
    transition-delay: 1.4s;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
          `
        : css`
            transform: translate3d(100%, 0, 0);
          `}
  }
`

export const Cards = styled.div`
  > img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.8s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(20%, 20%, 0);
          `}
  }

  > img:nth-child(1) {
    z-index: 1;
    top: 0;
    left: 5%;
    width: 36%;
    transition-delay: 0.2s;
  }
`
