import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Wrapper, Cards } from './styles.js'

import BgImg1 from '@images/works/oracle-cards/sec2-bg1.png'
import BgImg2 from '@images/works/oracle-cards/sec2-bg2.png'
import Card1 from '@images/works/oracle-cards/sec2-card1.png'
import Card2 from '@images/works/oracle-cards/sec2-card2.png'
import Card3 from '@images/works/oracle-cards/sec2-card3.png'

const Section2 = () => {
  const [line1InView, setLine1InView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setLine1InView(true)}
      threshold={0.25}
    >
      <Wrapper active={line1InView}>
        <img src={BgImg1} />
        <img src={BgImg2} />
        <Cards active={line1InView}>
          <img src={Card1} />
          <img src={Card2} />
          <img src={Card3} />
        </Cards>
      </Wrapper>
    </InView>
  )
}

export default Section2
