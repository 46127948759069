import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Wrapper, Cards } from './styles.js'

import BgImg1 from '@images/works/oracle-cards/sec5-bg1.png'
import BgImg2 from '@images/works/oracle-cards/sec5-bg2.png'
import BgImg3 from '@images/works/oracle-cards/sec5-bg3.png'
import BgImg4 from '@images/works/oracle-cards/sec5-bg4.png'
import Card1 from '@images/works/oracle-cards/sec5-card1.png'
import Card2 from '@images/works/oracle-cards/sec5-card2.png'
import Card3 from '@images/works/oracle-cards/sec5-card3.png'
import Card4 from '@images/works/oracle-cards/sec5-card4.png'
import Card5 from '@images/works/oracle-cards/sec5-card5.png'

const Section5 = () => {
  const [line1InView, setLine1InView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setLine1InView(true)}
      threshold={0.25}
    >
      <Wrapper active={line1InView}>
        <img src={BgImg1} />
        <img src={BgImg2} />
        <img src={BgImg3} />
        <img src={BgImg4} />
        <Cards active={line1InView}>
          <img src={Card1} />
          <img src={Card2} />
          <img src={Card3} />
          <img src={Card4} />
          <img src={Card5} />
        </Cards>
      </Wrapper>
    </InView>
  )
}

export default Section5
